import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";
import LogoImage from '../images/thumnail_blog_default.png';

const BlogPage = (props) => {
  const POST_PER_PAGE = 50;
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  //
  const ALL_POSTS = props.pageContext.posts;
  const allPostLength = ALL_POSTS.length;
  // get category name
  let categories = new Set();
  ALL_POSTS.forEach(p => {
    p.categories.nodes.forEach(c => categories.add(c.name));
  });
  categories = Array.from(categories);
  // alphabet
  categories.sort();
  //
  const isDisabledPrevious = () => {
    return currentIndex === 0;
  }

  const isDisabledNext = () => {
    return (currentIndex + POST_PER_PAGE) >= allPostLength;
  }

  const isPaging = () => {
    return selectedCategory === '';
  }

  const jump = (direction) => {
    if (direction) {
      setCurrentIndex(currentIndex + POST_PER_PAGE);
    } else {
      setCurrentIndex(currentIndex - POST_PER_PAGE);
    }
  }
  const seo = {
    metaDesc: 'Blog - Inventiv.org'
  }

  useEffect(() => {
    document?.body.classList.add('no-home');
  }, []);

  return (
    <Layout>
      <SEO title="Supporting inventors world-wide - Inventiv.org" canonical='/blog' seo={seo} />
      <div class="sub-banner main-banner">
        <div class="container">
          <div class="breadcrumb-area">
            <h1>Blogs</h1>
          </div>
        </div>
      </div>

      <div class="blog-section content-area">
        <div class="container">
          <div class="row">
            {
              ALL_POSTS.map((node, index) =>
                ((isPaging() && ((index >= currentIndex) && (index < (currentIndex + POST_PER_PAGE)))) || node.categories.nodes.some(c => c.name === selectedCategory)) ?
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="blog">
                      <div class="blog-photo">
                        {node.featuredImage?.node.localFile?.childImageSharp ?
                          <GatsbyImage
                            image={
                              node.featuredImage?.node.localFile?.childImageSharp?.gatsbyImageData
                            }
                            alt="blog"
                          />
                          : node.featuredImage?.node.mediaItemUrl ? <img src={node.featuredImage?.node.mediaItemUrl} height="300" width="100%" loading="lazy"  alt={node.title}/>
                            : <img src={LogoImage} height="230" loading="lazy" alt={node.title}/>
                        }
                      </div>
                      <div class="detail">
                        <h3>
                          <a href={"/blog/" + node.slug} >{node.title}</a>
                        </h3>
                        <div class="post-meta clearfix">
                          <span><a href="/"><i class="fa fa-user"></i></a>INVENTIV.ORG</span>
                          {/* <span><a href="#"><i class="fa fa-calculator"></i></a>17K</span> */}
                          {/* <span><a href="#"><i class="fa fa-commenting"></i></a>73k</span> */}
                        </div>
                        <p>
                          {node.seo?.metaDesc || node.seo?.focuskw || node.seo?.opengraphDescription || node.title}
                        </p>
                        <a href={"/blog/" + node.slug} class="d-clr" aria-label={node.title}>Read more</a>
                      </div>
                    </div>
                  </div>
                  :
                  null
              )
            }
          </div>
          <div className="row">
            <div className={isPaging() ? '' : 'd-none'}>
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  <li className={isDisabledPrevious() ? 'page-item disabled' : 'page-item'}>
                    <a className="page-link" href="#" onClick={evt => jump(false)} aria-disabled="true">Previous</a>
                  </li>
                  <li className={isDisabledNext() ? 'page-item disabled' : 'page-item'}>
                    <a className="page-link" href="#" onClick={evt => jump(true)}>Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
};

// export const query = graphql`
//   {
//     allWpPost {
//       nodes {
//         excerpt
//         content
//         id
//         uri
//         title
//         slug
//         date(fromNow: true)
//         categories {
//           nodes {
//             name
//           }
//       }
//       featuredImage {
//           node {
//             localFile {
//               childImageSharp {
//                 gatsbyImageData(width: 1920, placeholder: TRACED_SVG, height: 100)
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default BlogPage;
